import { 
	ChangePasswordData, ChangePasswordResponse, IChangePassword,
} from "@/types/models/services/user/changePassword";

import {
	ProfileDataResponse
} from "@/types/models/services/user/profileData";

import API from "./api";

enum Endpoints {
	ProfileData = "/users/",
	ChangePassword = "/user/password",
}

const changePassword = ({
	currentPassword,
	newPassword
}: IChangePassword) => {
	return new Promise<ChangePasswordResponse>(async (resolve, reject) => {
		API.put<ChangePasswordResponse, ChangePasswordData>({
			endpoint: Endpoints.ChangePassword,
			data: {
				current: currentPassword, 
				password: newPassword
			}
		})
		.then(res => {
			if(res.status === "ERROR") {
				reject(res);
			} else {
				resolve(res);
			}
		})
		.catch(err => {
			reject(err);
		})
	})
}

const getProfileData = () => {
	return new Promise<ProfileDataResponse>(async (resolve, reject) => {
		API.get<ProfileDataResponse>({
			endpoint: Endpoints.ProfileData
		})
		.then(res => {
			resolve(res);
		})
		.catch(err => {
			reject(err);
		})
	})
}

export default {
	getProfileData,
	changePassword
};