import API from "./api";
import { LeadContactSubmitData, LeadPowerSubmitData, LeadPowerSubmitResponse, LeadSubmitData } from "@/types/models/services/leads";

export enum Endpoints {
	Power = "/leads/potencia"
}

interface ISendLeadPowerParams {
	cups: string;
	data: LeadPowerSubmitData | LeadContactSubmitData;
}

const sendLeadPower = ({
	cups,
	data
}: ISendLeadPowerParams) => {
	return new Promise<LeadPowerSubmitResponse>(async (resolve, reject) => {
		API.post<LeadPowerSubmitResponse, LeadSubmitData>({
			endpoint: Endpoints.Power,
			data: {
				cups,
				...data
			}
		})
		.then(res => {
			resolve(res);
		})
		.catch(err => {
			reject(err);
		})
	})
}


export default {
  sendLeadPower
};