import { 
	FetchProductsResponse, FetchProductsParams
} from "@/types/models/services/products";
import API from "./api";

enum Endpoints {
	Pricelist = "/pricelist"
}

const fetchAvailableProducts = ({
	power,
	accessTariff,
	selfcons,
	cups,
	promotionalCode,
	lang
}: FetchProductsParams
) => {
	return new Promise<FetchProductsResponse>(async (resolve, reject) => {
		API.get<FetchProductsResponse>({
			endpoint: Endpoints.Pricelist,
			searchParams: [
				'?power', '=', power,
				'&atr', '=', accessTariff,
				'&cups', '=', cups,
				selfcons ? `&selfcons=${selfcons}` : '',
				promotionalCode ? `&promocode=${promotionalCode}` : '',
			].join(''), 
			params: {
				lang
			}
		})
		.then(res => {
			resolve(res)
		})
		.catch(err => {
			reject(err);
		})
	})
}

export default {
	fetchAvailableProducts
};


