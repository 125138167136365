import { ProfileDataResponse } from '@/types/models/services/user/profileData';
import { FETCH_PROFILE_REQUEST, RECEIVE_PROFILE, RECEIVE_PROFILE_KO } from '../constants/index'
import { default as userService } from "@/services/user";
import { Dispatch } from 'redux';

export function receiveProfile(data: ProfileDataResponse) {
  return {
    type: RECEIVE_PROFILE,
    payload: data,
  };
}

export function receiveProfileError() {
  return {
    type: RECEIVE_PROFILE_KO,
  };
}

export function fetchProfileRequest() {
  return {
    type: FETCH_PROFILE_REQUEST,
  };
}

export function fetchProfile() {
  return (dispatch: Dispatch) => {
    dispatch(fetchProfileRequest());
    userService.getProfileData()
    .then(response => {
        dispatch(receiveProfile(response));
    })
    .catch(err => {
        dispatch(receiveProfileError());
    })
  };
}