import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingAnimation } from "../LoadingAnimation";
import { cupsValid } from "../form-fields/CUPSField";
import TextFieldReadOnly from "../TextFieldReadOnly";
import LeadContact from "../LeadContact";
import {
  data_fetch_api_resource
} from "../../utils/http_functions";
import Settings from "../../settings";
import { i18n, dayjs } from "../../config";
import Result from "./Result";
import HelpPanel from "../HelpPanel";
import Cookies from '../../utils/cookies';
import { marketingHook } from "../../overrides/hooks/price-comparator";

import { 
  Button, MenuItem, Select, FormControl, Grid, FormHelperText, Divider,
  InputLabel, Paper, Radio, RadioGroup, FormControlLabel, Box, TextField, 
  Typography, Stack, useMediaQuery, useTheme
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from "react-i18next";
import { default as compareService } from "@/services/compare";
import NumericFormatCustom from "../ComparatorPower/fields/NumericFormatField";
import { LeadContactSubmitData, LeadPriceCompareSubmitResponse, LeadPriceSubmitData, PriceComparisonTariff } from "@/types/models/services/leads";
import useI18n from "@/hooks/useI18n";
import { ILanguage } from "@gisce/oficina-virtual-components";

// NOTE: This component is not ready for production!!
// TODO: Fix schema validation (enums) and add comparatorMaxConsumption validation
// FOLLOW: https://mui.com/material-ui/react-select/
// FOLLOW: https://timjames.dev/blog/validating-dependent-fields-with-zod-and-react-hook-form-2fa9

type ComparatorPriceFormData = Omit<
  LeadPriceSubmitData,
  "p1" | "p2" | "p3" | "p4" | "p5" | "p6" |"power" | "power2" | "invoiceAmount" | "accessTariff" | "startDate" | "endDate"
  > & {
    p1: string;
    p2: string;
    p3: string;
    p4: string;
    p5: string;
    p6: string;
    power: string;
    power2: string;
    invoiceAmount: string;
    accessTariff: string;
    comerOrigin: number;
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
}

const PriceComparator = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [invoiceSent, setInvoiceSent] = useState(false);
  const [mode, setMode] = useState("auto");
  const [availableComers, setAvailableComers] = useState<PriceComparisonTariff[]>([]);
  const [availableAccessTariffs, setAvailableAccessTariffs] = useState<PriceComparisonTariff[]>([]);
  const [saving, setSaving] = useState<LeadPriceCompareSubmitResponse | null>();

  // Zod validation schema
  const schema = z.object({
    cups: z.string()
      .min(1, t('common:text.required_field'))
      .refine(
        cups => cupsValid(cups),
        {message: t('common:text.contractation_cups_not_valid')}
      ),
    comerOrigin: z.number().positive({ message: t('common:text.required_field') }),
    accessTariff: z.string()
    .min(1, { message: t('common:text.required_field') }),
    p1: z.number({
      required_error: t('common:text.required_field'),
      }),
    startDate: z.date({
      errorMap: (issue) => ({
        message: issue.code === "invalid_date" ? t('common:text.date_not_valid') : t('common:text.required_field'),
      }),
      })
      .refine(
        date =>  date.getTime() <= Date.now(),
        {message: t('common:text.date_not_valid')}
      ),
    endDate: z.date({
      errorMap: (issue) => ({
        message: issue.code === "invalid_date" ? t('common:text.date_not_valid') : t('common:text.required_field'),
      }),
      })
      .refine(
        date => date.getTime() <= Date.now(),
        {message: t('common:text.date_not_valid')}
      ),
    power: z.number({
      required_error: t('common:text.required_field'),
      })
      .positive({
      message: t('common:text.power_field_not_valid'),
      }),
    power2: z.number()
      .positive({
      message: t('common:text.power_field_not_valid'),
      })
      .optional(),
    invoiceAmount: z.number({
      required_error: t('common:text.required_field'),
      })
      .positive({
      message: t('common:text.power_field_not_valid'),
      }),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    register,
    getValues,
    formState: { errors }
  } = useForm<ComparatorPriceFormData>({
    resolver: zodResolver(schema),
    // defaultValues: {
    // 	comerOrigin: {},
    // 	access_tariff: {},
    // 	startDate: null,
    // 	endDate: null,
    // }
  });

  // Important el segon argument
  // https://github.com/react-hook-form/react-hook-form/issues/3405
  const cups = watch("cups", '');
  const power = watch("power", '');

  const accessTariff = watch('accessTariff', '');
  const selectedTariff = availableAccessTariffs.find(tariff => tariff.id === Number(accessTariff));
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { currentLang } = useI18n();

  useEffect(() => {
    fetchComers(currentLang);
    if (Cookies.marketingConsentGiven()) {
      marketingHook();
    }
  }, []);

  useEffect(() => {
    if (cups.length === 20 || cups.length === 22) {
      fetchDataFromCUPS(cups);
    }
  }, [cups]);

  useEffect(() => {
    if (power) {
      fetchAvailableAccessTariffs(power);
    }
  }, [power]);

  const fetchComers = async (currentLang: ILanguage) => {
    const result = await compareService.fetchAvailableComersTariffs(currentLang.langCode);
    if (result) {
      setAvailableComers(result.comers);
    }
  };

  // TODO: Rewrite
  const fetchDataFromCUPS = async (cups: string) => {
    const result = await data_fetch_api_resource(
      null,
      `contractacio/config/cups/${cups}?old=0`
    );
    if (result && result.status === 200) {
      setValue("power", result.data?.power ?? "");
      setValue("accessTariff", String(result.data?.access_tariff?.id));
    }
  };

  const fetchAvailableAccessTariffs = async (power: string) => {
    let result = await compareService.fetchAvailableAccessTariffs({
      power: Number(power),
      old: 0
    });
    if (result) {
      if (accessTariff && result.tariffs.findIndex( elm => elm.id === Number(accessTariff)) === -1 ) {
        setValue("accessTariff", "");
      }

      setAvailableAccessTariffs(result.tariffs);
    }
  };

  const onComparatorPriceSubmit = async (formValues: ComparatorPriceFormData) => {
    const comparatorPriceSubmitValues: LeadPriceSubmitData = {
      comerOrigin: formValues.comerOrigin,
      consent: formValues.consent,
      mode: formValues.mode,
      startDate: dayjs(formValues.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(formValues.endDate).format("YYYY-MM-DD"),
      accessTariff: Number(formValues.accessTariff),
      invoiceAmount: Number(formValues.invoiceAmount),
      p1: Number(formValues.p1),
      p2: Number(formValues.p2),
      p3: Number(formValues.p3),
      p4: Number(formValues.p4),
      p5: Number(formValues.p5),
      p6: Number(formValues.p6),
      power: Number(formValues.power),
      power2: Number(formValues.power2),
      cups: formValues.cups,
    }
    setLoading(true);
    const response = await compareService.sendLeadCompare({cups: getValues("cups"), data: comparatorPriceSubmitValues});
    if (response) {
      setSaving(response);
    } else {
      setErrorText("Error: Could not compare");
    }
    setLoading(false);
  };

  const leadSubmit = async (leadContactData: LeadContactSubmitData) => {
    setLoading(true);
    const response = await compareService.sendLeadCompare({cups: getValues("cups"), data: leadContactData});
    if (response) {
      setInvoiceSent(true);
    } else {
      setErrorText("Invoice sending failed.");
    }
    setLoading(false);
  }

  const handleGoBack = () => {
    setSaving(null);
  };

  const handleHelpPanelClick = () => {
    if (i18n.exists('common:url.calculatorHelp')) {
      window.location.href = t('common:url.calculatorHelp');
    }
  };

  const renderHeader = () => {
    let modeSelectorParagraph = <p>{t('common:text.price_comparator_intro')}</p>
    let modeSelector = (
      <RadioGroup
        name="mode"
        defaultValue={"auto"}
        value={mode}
        onChange={(e) => setMode(e.target.value)}
      >
        <FormControlLabel value="auto" label={t('common:text.price_comparator_option_manual')} control={<Radio/>}/>
        <FormControlLabel
          value="manual"
          label={t('common:text.price_comparator_option_email')}
          control={<Radio/>}
        />
      </RadioGroup>
    );

    // INFO: By default will show the radio button `writed_data_or_attached_data`!
    if (Settings?.comparator?.calculationMode === "writed_data") {
      modeSelector = <></>;
      modeSelectorParagraph = <p>{t('common:text.price_comparator_for_writed_data_intro')}</p>
    }

    return <Stack direction={{ xs: 'column', md: 'row' }}>
        <Box
          component="img"
          src="/images/comparatorPrice/calculator.png"
          sx={{height: "auto", width: "175px", display: {xs: "none", md: "inherit"}}}
        />

        <Stack direction="column" spacing={2}>

          <Stack direction="row" spacing={{xs: 1, md: 0}}>
            <Box
              component="img"
              src="/images/comparatorPrice/calculator.png"
              sx={{height: "100px", width: "75px", display: {xs: "inherit", md: "none"}}}
            />
            <Typography variant="h5">
              {t('common:text.price_comparator_title',
                { companyName: Settings?.organization?.name })}
            </Typography>
          </Stack>

          { modeSelectorParagraph }
          {!saving && modeSelector}

        </Stack>

    </Stack>
  };

  const renderAutoForm = () => (
    <Stack direction={{xs: "column", lg: "row"}} spacing={2}>
      <Paper elevation={1} style={{ padding: "2.5rem", width: "100%" }}>
        <form onSubmit={handleSubmit(onComparatorPriceSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                {...register("cups")}
                label={"CUPS*"}
                style={{ width: "100%" }}
                error={!!errors.cups}
              />
              {errors.cups?.message &&
                <FormHelperText error>
                  {errors.cups.message}
                </FormHelperText>
              }
              <div className="helper-text">{t('common:text.contractation_cups_helper')}</div>
              <TextFieldReadOnly label={t('common:text.price_comparator_power_ask')} />
              <Controller
                name="power"
                control={control}
                render={({ field }) => <TextField
                style={{width: '100%'}}
                label={'P1*'}
                InputProps={{
                inputComponent: NumericFormatCustom,
                inputProps: {
                  suffix: " kW",
                  allowNegative: false,
                  allowedDecimalSeparators: [",", "."],
                  decimalScale: 3,
                }
                }}
                variant="standard"
                error={!!errors.power}
                {...field}
              />}
              />
              {errors.power?.message &&
                <FormHelperText error>
                  {errors.power.message}
                </FormHelperText>
              }
              <Controller
                name="power2"
                control={control}
                disabled={!selectedTariff || !/VE|3\.0TD|2.0TD/gm.test(selectedTariff.name)}
                render={({ field }) =>
                <TextField
                  style={{width: '100%'}}
                  label={'P2'}
                  InputProps={{
                  inputComponent: NumericFormatCustom,
                  inputProps: {
                    suffix:" kW",
                    allowNegative: false,
                    allowedDecimalSeparators: [",", "."],
                    decimalScale: 3,
                    decimalSeparator: ",",
                    thousandSeparator: ".",
                  }
                  }}
                  variant="standard"
                  error={!!errors.power2}
                  {...field}
                />}
              />
              {errors.power2?.message &&
                <FormHelperText error>
                  {errors.power2.message}
                </FormHelperText>
              }

            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="comerOrigin"
                control={control}
                defaultValue={0}
                render={({ field }) => {
                  const selectedComer = availableComers.find(comer => comer.id === Number(field.value));

                  return (
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel shrink>
                      {t('common:text.price_comparator_company') + "*"}
                    </InputLabel>
                    <Select
                      {...field}
                      value={selectedComer ? selectedComer.id : 0}
                      disabled={!availableComers || availableComers.length === 0}
                      onChange={(event) => field.onChange(event.target.value)}
                      error={!!errors.comerOrigin}
                      className="select-field"
                    >
                      {availableComers.map((comer) => (
                        <MenuItem key={comer.id} value={comer.id}>{comer.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  );
                }}
              />
              {errors.comerOrigin?.message &&
                <FormHelperText error>
                  {errors.comerOrigin.message}
                </FormHelperText>
              }
              <Controller
                name="accessTariff"
                control={control}
                render={({ field }) => (
                  <FormControl style={{ width: "100%", marginTop: isLargeScreen ? 70 : 20 }}>
                    <InputLabel shrink>
                      {t('common:text.price_comparator_access_tariff') + "*"}
                    </InputLabel>
                    <Select
                      value={field.value || ''}
                      onChange={(event) => {
                        setValue("accessTariff", event.target.value);
                      }}
                      disabled={!availableAccessTariffs || availableAccessTariffs.length === 0}
                      className="select-field"
                    >
                      {availableAccessTariffs?.map(c => (
                        <MenuItem key={c.id} value={String(c.id)}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />

              {errors.accessTariff?.message &&
                <FormHelperText error>
                  {errors.accessTariff.message}
                </FormHelperText>
              }
            </Grid>
          </Grid>

          <Divider style={{ marginTop: 20 }} />
          <TextFieldReadOnly label={t('common:text.price_comparator_consumption')} />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <Controller
                name="p1"
                control={control}
                disabled={!selectedTariff || !/VE|3\.0TD|2.0TD/gm.test(selectedTariff.name)}
                render={({ field }) =>
                  <TextField
                    sx={{width: "100%"}}
                    label="P1*"
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      inputProps: {
                        suffix: " kWh",
                        allowNegative: false,
                        allowedDecimalSeparators: [",", "."],
                        decimalScale: 0,
                        decimalSeparator: ",",
                        thousandSeparator: ".",
                      }
                    }}
                    variant="standard"
                    error={!!errors.p1}
                    {...field}
                  />
                }
              />
              {errors.p1?.message &&
                <FormHelperText error>
                  {errors.p1.message}
                </FormHelperText>
              }
              <Controller
                name="p4"
                control={control}
                disabled={!selectedTariff || !/VE|3\.0TD/gm.test(selectedTariff.name)}
                render={({ field }) =>
                  <TextField
                    sx={{width: "100%"}}
                    label="P4"
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      inputProps: {
                        suffix: " kWh",
                        allowNegative: false,
                        allowedDecimalSeparators: [",", "."],
                        decimalScale: 0,
                        decimalSeparator: ",",
                        thousandSeparator: ".",
                      }
                    }}
                    variant="standard"
                    error={!!errors.p4}
                    {...field}
                  />
                }
              />
              {errors.p4?.message &&
                <FormHelperText error>
                  {errors.p4.message}
                </FormHelperText>
              }
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Controller
                name="p2"
                control={control}
                disabled={!selectedTariff || !/VE|3\.0TD|2.0TD/gm.test(selectedTariff.name)}
                render={({ field }) =>
                  <TextField
                    sx={{width: "100%"}}
                    label="P2"
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      inputProps: {
                        suffix: " kWh",
                        allowNegative: false,
                        allowedDecimalSeparators: [",", "."],
                        decimalScale: 0,
                        decimalSeparator: ",",
                        thousandSeparator: ".",
                      }
                    }}
                    variant="standard"
                    error={!!errors.p2}
                    {...field}
                  />
                }
              />
              {errors.p2?.message &&
                <FormHelperText error>
                  {errors.p2.message}
                </FormHelperText>
              }
              <Controller
                name="p5"
                control={control}
                disabled={!selectedTariff || !/VE|3\.0TD/gm.test(selectedTariff.name)}
                render={({ field }) =>
                  <TextField
                    sx={{width: "100%"}}
                    label="P5"
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      inputProps: {
                        suffix: " kWh",
                        allowNegative: false,
                        allowedDecimalSeparators: [",", "."],
                        decimalScale: 0,
                        decimalSeparator: ",",
                        thousandSeparator: ".",
                      }
                    }}
                    variant="standard"
                    error={!!errors.p5}
                    {...field}
                  />
                }
              />
              {errors.p5?.message &&
                <FormHelperText error>
                  {errors.p5.message}
                </FormHelperText>
              }
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Controller
                name="p3"
                control={control}
                disabled={!selectedTariff || !/VE|3\.0TD|2.0TD/gm.test(selectedTariff.name)}
                render={({ field }) =>
                  <TextField
                    sx={{width: "100%"}}
                    label="P3"
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      inputProps: {
                        suffix: " kWh",
                        allowNegative: false,
                        allowedDecimalSeparators: [",", "."],
                        decimalScale: 0,
                        decimalSeparator: ",",
                        thousandSeparator: ".",
                      }
                    }}
                    variant="standard"
                    error={!!errors.p3}
                    {...field}
                  />
                }
              />
              {errors.p3?.message &&
                <FormHelperText error>
                  {errors.p3.message}
                </FormHelperText>
              }
              <Controller
                name="p6"
                control={control}
                disabled={!selectedTariff || !/VE|3\.0TD/gm.test(selectedTariff.name)}
                render={({ field }) =>
                  <TextField
                    sx={{width: "100%"}}
                    label="P6"
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      inputProps: {
                        suffix: " kWh",
                        allowNegative: false,
                        allowedDecimalSeparators: [",", "."],
                        decimalScale: 0,
                        decimalSeparator: ",",
                        thousandSeparator: ".",
                      }
                    }}
                    variant="standard"
                    error={!!errors.p6}
                    {...field}
                  />
                }
              />
              {errors.p6?.message &&
                <FormHelperText error>
                  {errors.p6.message}
                </FormHelperText>
              }
            </Grid>
          </Grid>

          <Divider style={{ marginTop: 20 }} />
          <TextFieldReadOnly label={t('common:text.price_comparator_invoicing_period')} />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      sx={{ width: "100%" }}
                      value={field.value || null}
                      onChange={(val) => field.onChange(val ? val.toDate() : null)}
                      label={t('common:text.date_interval_start') + "*"}
                      slotProps={{
                        textField: {
                          error: !!errors.startDate,
                        },
                      }}
                    />
                  )}
                />
                {errors.startDate?.message &&
                  <FormHelperText error>
                    {errors.startDate.message}
                  </FormHelperText>
                }
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      sx={{width: "100%"}}
                      value={field.value || null}
                      onChange={(val) => field.onChange(val ? val.toDate() : null)}
                      label={t('common:text.date_interval_end') + "*"}
                      slotProps={{
                        textField: {
                          error: !!errors.endDate
                        }
                      }}
                    />
                  )}
                />
                {errors.endDate?.message &&
                  <FormHelperText error>
                    {errors.endDate.message}
                  </FormHelperText>
                }
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name="invoiceAmount"
                  control={control}
                  render={({ field }) => <TextField
                      sx={{width: "100%"}}
                      label={t('common:text.price_comparator_invoice_amount') + "*"}
                      fullWidth={true}
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                        inputProps: {
                          suffix: " €",
                          allowNegative: false,
                          allowedDecimalSeparators: [",", "."],
                          decimalScale: 0,
                          decimalSeparator: ",",
                          thousandSeparator: ".",
                        }
                      }}
                      variant="standard"
                      error={!!errors.invoiceAmount}
                      {...field}
                    />}
                />
                {errors.invoiceAmount && <FormHelperText error>{errors.invoiceAmount.message}</FormHelperText>}
              </Grid>
            </Grid>
          </LocalizationProvider>

          <Divider sx={{mt: 3, mb: 3}}/>
          <Button
            type="submit"
            color={'primary'}
            variant={'contained'}
            className="primary-btn submit-btn"
          >
            {t('common:text.price_comparator_submit')}
          </Button>
        </form>
      </Paper>
      {Settings?.comparator?.sideContact && (
        <HelpPanel onClick={handleHelpPanelClick} />
      )}
    </Stack>
  );

  const renderManualForm = () => (
    <Paper elevation={1} style={{ padding: "2.5rem" }}>
      <div className="contact-container">
        <h2>{t('common:text.price_comparator_contact_subtitle')}</h2>
        <LeadContact leadSubmit={leadSubmit} attachInvoice={true} />
      </div>
    </Paper>
  );

  return (
    <div className="price-comparator-electricity">
      {loading && <LoadingAnimation />}
      {invoiceSent ? (
        <h3>{t('common:text.price_comparator_contact_sent')}</h3>
      ) : !loading && !saving ? (
        <div>
          {renderHeader()}
          {mode === "auto" && renderAutoForm()}
          {mode === "manual" && renderManualForm()}
        </div>
      ) : (
        !loading && <Result cups={cups} saving={saving} onGoBack={handleGoBack} />
      )}
      <div style={{ color: "#f00" }}>{errorText}</div>
    </div>
  );
};

export default PriceComparator;
