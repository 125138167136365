import { RootState } from "@/store"
import { ProfileDataV1 } from "./types/profile";
import { UserInfo as UserInfoComponent } from "@gisce/oficina-virtual-components"
import { useSelector } from "react-redux"

export const UserInfo = () => {
	const {data: untypedData, isFetching} = useSelector((state: RootState) => state.profile);
	
	const profileData = untypedData && (untypedData as ProfileDataV1).result;

	return profileData ? <UserInfoComponent 
		avatarPosition="left" 
		email={profileData.email}
		name={profileData.name}
		loading={isFetching}
	/> : <></>
}