import API from "./api";
import { LeadContactSubmitData, LeadPriceSubmitData, LeadPriceCompareSubmitResponse, LeadSubmitData, AvailableTariffsSubmitData, AvailableTariffsSubmitResponse, AvailableComersResponse, AvailableComersResponseAPI, AvailableTariffsSubmitResponseAPI } from "@/types/models/services/leads";
import { ILanguage } from "@gisce/oficina-virtual-components";

export enum Endpoints {
	Compare = '/price-compare/',
	CompareConfirm = '/price-compare/confirm',
	LeadComers = '/lead/comers/',
	Tariffs = '/tariff/'
}

// TODO: Revisar quins endpoints s'ha de fer servir en quines crides

interface ISendLeadCompareParams {
	cups: string;
	data: LeadPriceSubmitData | LeadContactSubmitData;
}

const sendLeadCompare = ({
	cups,
	data,
}: ISendLeadCompareParams) => {
	return new Promise<LeadPriceCompareSubmitResponse>(async (resolve, reject) => {
		API.post<LeadPriceCompareSubmitResponse, LeadSubmitData>({
			endpoint: Endpoints.Compare,
			data: {
				cups,
				...data
			}
		})
		.then(res => {
			resolve(res);
		})
		.catch(err => {
			reject(err);
		})
	})
}

const fetchAvailableAccessTariffs = (data: AvailableTariffsSubmitData) => {
	return new Promise<AvailableTariffsSubmitResponse>(async (resolve, reject) => {
		API.get<AvailableTariffsSubmitResponse>({
			endpoint: Endpoints.Tariffs,
			params: { // TODO: Check if this works
				power: data.power,
				old: data.old
			}
		})
		.then(res => {
			resolve(res);
		})
		.catch(err => {
			reject(err);
		})
	})
}

const fetchAvailableComersTariffs = (
  lang: string
) => {
	return new Promise<AvailableComersResponse>(async (resolve, reject) => {
		API.get<AvailableComersResponse>({
			endpoint: Endpoints.Compare,
      params: { lang }
		})
		.then(res => {
			resolve(res);
		})
		.catch(err => {
			reject(err);
		})
	})
}


export default {
  sendLeadCompare,
  fetchAvailableAccessTariffs,
  fetchAvailableComersTariffs
};