import {
  NewsletterFetchResponse,
  NewsletterRead,
  SubscriberRead,
  OfuscatedId,
  SubscriberEmails,
  SubscriberId,
} from "@/types/models/services/newsletter";
import API from "./api";

enum Endpoints {
  FetchSubscribers = "/subscribers/:subscriber_id",
  UpdateSubscriber = "/subscriber/set_active_newsletters",
  UpdateMails = "/subscriber/set_mails",
  Newsletters = "/newsletters/:subscriptor_id_or_email",
}

const fetchSubscriber = async (
  subscriberId: OfuscatedId
): Promise<SubscriberRead | false> => {
  try {
    const response = await API.get<SubscriberRead | false>({
      endpoint: Endpoints.FetchSubscribers,
      params: {
        subscriber_id: subscriberId
      },
    });
    
    return response ?? false;
  } catch (error) {
    console.error("Error fetching subscriber:", error);
    return false;
  }
};

const fetchNewsletters = async (
  subscriberIdOrEmail: SubscriberEmails | SubscriberId
): Promise<NewsletterRead[]> => {
  try {
    const response = await API.get<NewsletterFetchResponse[]>({
      endpoint: Endpoints.Newsletters,
      params: {
        subscriptor_id_or_email: subscriberIdOrEmail
      },
    });
    return response.map((newsletter) => ({
      id: newsletter.newsletter_id,
      name: newsletter.name,
      subscribed: newsletter.subscribed,
      subscriberId: newsletter.subscriptor_id,
      newsletterExported: newsletter.exported,
      newsletterDescription: newsletter.newsletter_description,
    }));
  } catch (error) {
    console.error("Error fetching newsletters:", error);
    throw new Error("Failed to load the newsletters. Please try again later.");
  }
};

const setActiveNewsletterSubscriptions = async (
  subscriberIdOrEmail: SubscriberEmails | SubscriberId,
  selectedNewsletters: NewsletterRead["id"][],
): Promise<NewsletterRead[]|"removed"> => {
  try {
    const response = await API.post<
      NewsletterFetchResponse[]|"removed",
      {
        subscriptor_or_email: SubscriberEmails | SubscriberId;
        selected_newsletters: NewsletterRead["id"][];
      }
    >({
        endpoint: Endpoints.UpdateSubscriber,
        data: {
          subscriptor_or_email: subscriberIdOrEmail,
          selected_newsletters: selectedNewsletters,
        }
      });
    if (response === "removed") {
      return response
    } else {
      return response.map((newsletter) => ({
        id: newsletter.newsletter_id,
          name: newsletter.name,
        subscribed: newsletter.subscribed,
        subscriberId: newsletter.subscriptor_id,
        newsletterExported: newsletter.exported,
        newsletterDescription: newsletter.newsletter_description,
      }));
    }
  } catch (error) {
    console.error("Error saving subscriptions", error);
    throw new Error("Failed to save the newsletter preferences. Please try again later.");
  }
};

const setNewsletterSubscriptorMails = async (
  subscriberId: SubscriberId,
  emails: SubscriberEmails,
): Promise<boolean> => {
  try {
    const response = await API.post<
      boolean,
      {
        subscriberId: SubscriberId;
        emails: SubscriberEmails;
      }
    >({
      endpoint: Endpoints.UpdateMails, 
      data: {
        subscriberId,
        emails,
      }
    });
    return response;
  } catch (error) {
    console.error("Error saving subscriptor mails", error);
    throw new Error("Failed to save the email modification. Please try again later.");
  }
};

export default {
  fetchNewsletters,
  fetchSubscriber,
  setActiveNewsletterSubscriptions,
  setNewsletterSubscriptorMails,
};
