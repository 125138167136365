
import { 
  AvailableEndpoints, 
  IEndpointRequest, 
  IGetEndpointRequest,
  IPostEndpointRequest,
  IPutEndpointRequest,
  ValidateEnpointData
} from "@/types/definitions/apiCalls";
import * as Sentry from "@sentry/browser";
import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios";

const API_PREFIX = "/api/";

class API {
  static routes: AvailableEndpoints = {};
  static fetching = false;
  static fetched = false;

  static init() {
    this.routes = {};
    this.fetching = true;
    this.fetched = false;
    // fetch 
    axios.get<AvailableEndpoints>(API_PREFIX + 'v1/metadata/endpoints')
    .then((res) => {
      this.routes = res.data;
      this.fetching = false;
      this.fetched = true;
    })
    .catch(err => {
      Sentry.captureException(err);
      this.fetching = false;
    });
  }

  static #validateEndpoint({
    endpoint,
    method,
    versions
  }: ValidateEnpointData) {
    return new Promise<ValidateEnpointData>((resolve, reject) => {
      let interval = setInterval(() => {
        if (this.fetching) {
          return;
        }
        clearInterval(interval);

        if (!this.routes?.[endpoint]){
          const RuteNotFoundException = new Error('Route not found.');
          Sentry.captureException(RuteNotFoundException);
          reject(RuteNotFoundException);
        }

        // If no versions are provided we will try all the available ones
        if (!versions || !versions.length) {
          versions = Object.keys(this.routes[endpoint].versions).map(Number);
          versions = versions.sort((a, b) => b - a);
        }

        let validatedVersions: number[] = [];
        for (const version of versions) {
          if (this.routes[endpoint].versions?.[version].includes(method)) {
            validatedVersions.push(version);
          }
        }

        if (!validatedVersions.length) {
          const RuteVersionNotFoundException = new Error('No available versions found for this method.');
          Sentry.captureException(RuteVersionNotFoundException);
          reject(RuteVersionNotFoundException);

        } else {
          resolve({endpoint, method, versions: validatedVersions});
        }

      }, 300);
    })
  }

  static #constructEndpoint(
    route: string, 
    version: number, 
    params?: Record<string, unknown>,
    searchParams?: string
  ) {
    let constructedRoute = route;
    for (const param in params) {
      constructedRoute = constructedRoute.replace(`:${param}`, params[param] as string);
    }
    return [API_PREFIX, `v${version}`, constructedRoute, searchParams].join('');
  }

  static async #doRequest<TResponse>({
    method,
    endpoint,
    versions = [],
    params,
    searchParams, 
    config,
    data
  }: IEndpointRequest) : Promise<AxiosResponse<TResponse>> {
    try {
      const currentVersion = versions[0];
      switch(method) {
        case "get":
        case "GET":
          return await  axios.get<TResponse>(this.#constructEndpoint(endpoint, currentVersion, params, searchParams), config);
        case "put":
        case "PUT":
          return await  axios.put<TResponse>(this.#constructEndpoint(endpoint, currentVersion, params, searchParams), data, config);
        case "post":
        case "POST":
          return await  axios.post<TResponse>(this.#constructEndpoint(endpoint, currentVersion, params, searchParams), data, config);
        default:
          throw new Error("El method demanat no existeix");
      }

    } catch (err) {
      if(versions.length > 1) {
        return  this.#doRequest({
          method,
          endpoint,
          versions: versions.slice(1),
          params,
          searchParams,
          config,
          data
        });
      } else {
        Sentry.captureException(err);
        throw err
      } 
    }
  }

  static async get<TResponse>({
    endpoint, 
    versions,
    params,
    searchParams,
    config
  }: IGetEndpointRequest) {
    try {
      const validEndpoint = await this.#validateEndpoint({
        endpoint, method: "GET", versions
      });
      const response = await this.#doRequest<TResponse>({
        ...validEndpoint, 
        params, searchParams, config
      })
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async put<TResponse, TData>({
    endpoint,
    versions,
    params,
    searchParams,
    config,
    data
  }: IPutEndpointRequest<TData>): Promise<TResponse> {
    try {
      const validEndpoint = await this.#validateEndpoint({
        endpoint, method: "PUT", versions
      });
      const response = await this.#doRequest<TResponse>({
        ...validEndpoint,
        params, searchParams, config, data
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async post<TResponse, TData>({
    endpoint,
    versions,
    params,
    searchParams,
    config,
    data
  }: IPostEndpointRequest<TData>): Promise<TResponse> {
    try {
      const validEndpoint = await this.#validateEndpoint({
        endpoint, method: "POST", versions
      });
      const response = await this.#doRequest<TResponse>({
        ...validEndpoint,
        params, searchParams, config, data
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }
}


export default API;