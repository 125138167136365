import InvoicesElectricityContent from './InvoicesElectricityContent';
import InvoicesListGas from './InvoicesListGas';
import InvoicesMultipointContent from "./InvoicesMultipointContent";

import { features } from "../../settings";
import { Tabs, Tab, Box } from '@mui/material';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';

import GasIcon from '../../assets/GasIcon';
import LightIcon from '../../assets/LightIcon';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const InvoicesTabs = (props) => {
  const { t } = useTranslation();
  
  const settings = useSelector((state) => state.settings.data);

  const [invoiceType, setInvoiceType] = useState(
    settings?.showInvoicesMultipunt ? "multipoint" :
    props?.showSpecificType ? props.showSpecificType :
    features.displayTypes[0]
  ); // 'electricity', 'gas',  'multipoint'

  const handleChangeInvoiceType = (event, value) => {
    setInvoiceType(value);
  };

  function showTab(type) {
    return ((props?.showSpecificType && invoiceType == type) || (!props?.showSpecificType && features.displayTypes.includes(type)))
  }

  return <Box sx={{ width: '100%', marginTop: -2 }}>
    {features.displayTypes.length > 1 || settings?.showInvoicesMultipunt ? <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={invoiceType} onChange={handleChangeInvoiceType}>
          {settings?.showInvoicesMultipunt && <Tab label={t("common:text.contracts_tab_title_multipoint")} value="multipoint" icon={<DeviceHubIcon />}/>}
          {showTab('electricity') &&
           <Tab label={t("common:text.contracts_tab_title_electricty")} value="electricity" icon={<LightIcon />}/>
          }
          {showTab('gas') &&
            <Tab label={t("common:text.contracts_tab_title_gas")} value="gas" icon={<GasIcon />}/>
          }
        </Tabs>
      </Box>
      <Box sx={{mt: 2}}>
        {settings?.showInvoicesMultipunt && <div hidden={invoiceType !== 'multipoint'}><InvoicesMultipointContent/></div>}
        {features.displayTypes.includes('electricity') && <div hidden={invoiceType !== 'electricity'}><InvoicesElectricityContent/></div>}
        {features.displayTypes.includes('gas') && <div hidden={invoiceType !== 'gas'}><InvoicesListGas/></div>}    
      </Box>
    </> :
    <>
      {settings?.showInvoicesMultipunt && <InvoicesMultipointContent/>}
      {features.displayTypes[0] === 'electricity' && <InvoicesElectricityContent/>}
      {features.displayTypes[0] === 'gas' && <InvoicesListGas/>}
    </>}
  </Box>;

}

export default InvoicesTabs;