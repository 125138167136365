
import API from "./api";
import { OIDCEndLoginParam, OIDCEndLoginResponse } from "@/types/models/hooks";

// static getUsers(): Promise<User[]> {
// 	return new Promise<User[]>((resolve, reject) => {
// 		API.get(`${ENDPOINT}/users`)
// 			.then((value: User[]) => {
// 				const formataatexmep = values.map()
// 				resolve(formataatexmep)
// 			}).catch((error) => {
// 				reject("oh no")
// 				reject(error)
// 			})
// 	})
// }

enum Endpoints {
	LoginOpenID = "/user/openid"
}

const isOIDCEndLoginRes = (maybeOIDCEndLoginRes: any): maybeOIDCEndLoginRes is OIDCEndLoginResponse => {
  return maybeOIDCEndLoginRes && (
		typeof maybeOIDCEndLoginRes?.token === 'string' &&
		typeof maybeOIDCEndLoginRes?.openid_token === 'string' &&
		typeof maybeOIDCEndLoginRes?.new_customer === 'boolean'
  )
}

const login = ({
	code,
	nonce
}: OIDCEndLoginParam) => {
	return new Promise<OIDCEndLoginResponse>(async (resolve, reject) => {
		API.post<OIDCEndLoginResponse, OIDCEndLoginParam>({
			endpoint: Endpoints.LoginOpenID,
			data: {
				code, 
				nonce
			}
		})
		.then(res => {
			if(isOIDCEndLoginRes(res)) {
				resolve(res);
			} else {
				reject(res);
			}
		})
		.catch(err => {
			reject(err);
		})
	})
}

export default {
	login
};