// import { TConsumptionGenerationItem, TGetConsumptionGenerationParams } from "../types";

import { FetchCouponsResponse, ValidateCouponResponse } from "@/types/models/services/coupons";
import API from "./api";
import { IPublicityBannerProps } from "@gisce/oficina-virtual-components";
import { isCoupon } from "@/types/models/guards";

enum Endpoints {
	FetchCoupons = '/user/coupons/',
	ValidateCoupon = '/leads/validate-coupon/<string:code>/',
}

const fetchCoupons = (lang: string) => {

	const parseCoupon = (coupon: FetchCouponsResponse[0]) => {
		const parsedCoupon: IPublicityBannerProps["dataProps"] = {
			shareURL: coupon.code_url,
			title: coupon.campaign.name,
			description: coupon.campaign.description,
			infoURL: coupon.campaign.url,
		}
		return parsedCoupon;
	}

	return new Promise<IPublicityBannerProps["dataProps"][]>(async (resolve, reject) => {
		API.get<FetchCouponsResponse>({
			endpoint: Endpoints.FetchCoupons,
			config: {
				lang
			}
		})
		.then(res => {
			// Typeguard
			try {
				resolve(
					res.map(coupon => !isCoupon(coupon) ? parseCoupon(coupon) : coupon)
				);
			} catch (err) {
				reject(err);
			}
		})
		.catch(err => {
			reject(err);
		})
	})
}

const validateCoupon = (coupon: string) => {

	return new Promise<ValidateCouponResponse>(async (resolve, reject) => {
		API.get<ValidateCouponResponse>({
			endpoint: Endpoints.ValidateCoupon,
			params: {
				coupon
			}
		})
		.then(res => {
			resolve(res);
		})
		.catch(err => {
			reject(err);
		})
	})
}

export default {
	fetchCoupons,
	validateCoupon
};